.BlogPreview {
    height: 20px;
}

.Content {
    flex-grow: 1;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.BlogContent {
    align-items: normal;
    justify-content: left;
}

.List {
    width: 75%;
}

.Title {
    display: flex;
    align-items: center;
    font-weight: bold; 
    border: 0;
    font-size: 34px;
    color: #fff;
}

.TitleBlock {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
}

.custom-link {
    color: rgb(148,148,148); 
    text-decoration: none; 
    transition: color 0.3s; 
  }

  .custom-link:hover {
    color: #fff;
  }

.blog-list-item {
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 1rem 0; 
    background-color: #000000;
  }
  
  .blog-title {
    display: flex;
    align-items: center;
    font-weight: bold; 
    font-size: 25px;
  }
  
  .blog-date {
    font-style: italic; 
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 15px;
  }
  
  .blog-list-item hr {
    width: 100%;
    border: 0;
    border-top: 1px solid #ccc;
    margin-top: 1rem;
    margin-bottom: 0; 
  }

.page-item {
    margin: 2px;
}

.page-link {
    background-color: #000000;
    --bs-pagination-border-color: rgb(148,148,148);
    --bs-pagination-active-color: #000000;

    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius);
    border-top-right-radius: var(--bs-pagination-border-radius);
    border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination {
    --bs-pagination-hover-bg: #424649;
    --bs-pagination-hover-border-color: #000000;
    --bs-pagination-active-color: #000000;
    --bs-pagination-focus-color: #fff;
    --bs-pagination-focus-bg: #2c2b2b;

    --bs-pagination-color: rgb(148,148,148);
    --bs-pagination-hover-color: #fff;
}

.active > .page-link {
    background-color: rgb(148,148,148);
    border-color: #000000;
}

.page-link:hover {
    border-color: #fff;
    color: #fff;
}

.btn-dark
{
  --bs-btn-color: rgb(148,148,148);
  --bs-btn-bg: #000000;
  --bs-btn-border-color: rgb(148,148,148);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 66,70,73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(148,148,148);
  --bs-btn-active-border-color: rgb(148,148,148);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
  margin-bottom: 16px;
}

.Triple {
  margin-bottom: 40px;
}

.FlexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.TripleContainer {
  width: 33%;
}

.TripleItem {
  width: 33%;
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.TripleTitle {
  border-bottom: 1px solid #ccc;
  margin-top: 16px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.TripleIcon {
  width: 25%;
}

.TripleContent {
    align-items: normal;
    justify-content: left;
}

.Center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AboutContainer {
  width: 75%;
}

.Headline {
  width: 75%;
  margin: auto;
  font-size: 26px;
  margin-top: 10%;
  margin-bottom: 10%;
}