.Home {
  background-color: #000000;
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.Home-header {
    background-color: #000000;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .MainTitle {
    opacity: 0.65; /* Default opacity */
    transition: opacity 0.5s ease; /* Smooth transition for opacity change */
  }

  .MainTitle:hover {
    opacity: 1; /* Opacity level when hovered */
  }

  @keyframes Home-logo-animation {
    0% {
      opacity: 1%;
    }
    50% {
      opacity: 100%;
    }
    100% {
        opacity: 1%;
      }
  }

  .Home-logo {
    height: 20vmin;
    pointer-events: none;
    animation: Home-logo-animation infinite 6s ease;
  }